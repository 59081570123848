import React, { useEffect } from "react";
//import { Link } from "gatsby";
import { Link } from "gatsby-plugin-react-i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Controller } from "swiper/core";
import "./smallCellHorizontalBlock.scss";

SwiperCore.use([Pagination, Navigation, Controller]);

const SmallCellHorizontalBlock = ({ data: { heading, subheading, items } }) => {
  useEffect(() => {
    const detailBlocks = Array.from(
      document.getElementsByClassName("people-detail")
    );
    window.onresize = () => {
      calculateHeighOfDetailBlocks(detailBlocks);
    };
    window.onload = () => {
      calculateHeighOfDetailBlocks(detailBlocks);
    };
  });

  const calculateHeighOfDetailBlocks = (detailBlocks) => {
    let maxHeight = 100;
    detailBlocks.forEach((detailBlock) => {
      maxHeight =
        maxHeight > detailBlock.clientHeight
          ? maxHeight
          : detailBlock.clientHeight;
    });
    setHeightOfDetailBlocks(maxHeight);
  };

  const setHeightOfDetailBlocks = (maxHeight) => {
    const detailBlocks = document.getElementsByClassName("people-detail");
    for (var i = 0; i < detailBlocks.length; i++) {
      detailBlocks[i].style.height = maxHeight + "px";
    }
  };

  return (
    <section className="real-people">
      <div className="container">
        {heading && <h4 className="real-people-title">{parse(heading)}</h4>}
        {subheading && <p className="real-people-description">{subheading}</p>}
        <div className="real-people-slider">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={14}
            centeredSlides={true}
            loop={true}
            observer={true}
            loopFillGroupWithBlank={true}
            pagination={false}
            breakpoints={{
              575: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 26,
                centeredSlides: false,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 26,
                centeredSlides: false,
              },
            }}
            className="mySwiper"
          >
            {items?.map((item) => (
              <SwiperSlide key={item.id} className="relative">
                <Link to="#">
                  <div className="white-wrap">
                    <GatsbyImage
                      image={getImage(item.desktopImage)}
                      alt={item.heading}
                    />
                    <div className="people-detail">
                      <p>{item.body}</p>
                      <h5>
                        {item.heading}
                        <span>{item.subheading}</span>
                      </h5>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default SmallCellHorizontalBlock;

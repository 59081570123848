import React, { useState, useEffect, useRef } from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import "./planBasicBlock.scss";

const PlanBasicBlock = ({ data: { items } }) => {
  return (
    <>
      <section className="fitplan-basic-section">
        <div className="container">
          <div className="filter-plan-basic">
            {items.map(
              ({ heading, subheading, desktopImage, mobileImage }, key) => {
                return (
                  <div className="single-plan-basic" key={key}>
                    <div className="image">
                      <GatsbyImage
                        image={getImage(desktopImage)}
                        alt={heading}
                        className="desk"
                      />
                      <GatsbyImage
                        image={getImage(mobileImage)}
                        alt={heading}
                        className="mobi"
                      />
                    </div>
                    <h2 className="heading-basic">{heading}</h2>
                    <h3 className="type-basic">{subheading}</h3>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default PlanBasicBlock;

export const BlockTypes = {
  fullPageHorizontalCarousel: "Full page horizontal carousel",
  smallCellHorizontalCarousel: "Small cell horizontal carousel",
  verticalInPlaceCarousel: "Vertical in-place carousel",
  twoColumnTextImageBlock: "Two Column Text Image Block",
  twoColumnTextVideoBlock: "Two Column Text Video Block",
  smallCellHorizontalBlock: "Small Cell Horizontal block",
  textBlockWithBackgroundImage: "Text block with background Image",
  twoColumnContentRichImageBlock: "Two Column ContentRich Image Block",
  twoColumnImageTextBlock: "Two Column Image Text Block",
  aboutTrainerBlock: "About Trainer",
  trainerGearBlock: "Trainer Gear",
  trainerTestimonialBlock: "Trainer Testimonials",
  faqBlock: "Faq",
  trainerListBlock: "Trainer List Block",
  planListBlock: "Plan List Block",
  plansSmallCellHorizontalCarousel: "Plans Small Cell Horizontal Carousel",
  planBasicInfoBlock: "Plan basic info Block",
  twoColumnTextVideoRoundedBlock: "Two Column Text VideoRounded Block",
  textOnlyBlock: "Text Only Block",
  staticHeroBlockWithBackgroundImage: "Static Hero Block With Background Image",
  staticFooterBlockWithBackgroundImage:
    "Static Footer Block With Background Image",
  twoColumnBlockWithImagesOnly: "Two Column Block With Images Only",
};

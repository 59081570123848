import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import striptags from "striptags";
import "./staticFooterBlockWithBackgroundImage.scss";

const StaticFooterBlockWithBackgroundImage = ({ data }) => {
  const { id, heading, subheading, desktopImage, backgroundImage } =
    data.items[0];
  return (
    <div id={id} className="get-fitplan-landing">
      <GatsbyImage
        image={getImage(backgroundImage)}
        alt={striptags(heading)}
        placeholder="blurred"
        layout="constrained"
      />
      <div className="get-fitplan-abs-section">
        <div className="container">
          {heading && <h4 className="main-title">{parse(heading)}</h4>}
          {subheading && (
            <p className="main-description">{parse(subheading)}</p>
          )}
          <div className="qr-image-fitplan">
            <GatsbyImage
              image={getImage(desktopImage)}
              alt={striptags(heading)}
              placeholder="blurred"
              layout="constrained"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaticFooterBlockWithBackgroundImage;

import React, { useEffect, useState } from "react";
//import { Link } from "gatsby";
import { Link } from "gatsby-plugin-react-i18next";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { getSymbolFromCurrency } from "../../global/currency";
import { Swiper, SwiperSlide } from "swiper/react";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import "swiper/swiper-bundle.css";
import "swiper/components/pagination/pagination.min.css";
import "./verticalInPlaceCarousel.scss";
import { CTAButton } from "../../lib/components/Button/CTAButton";

const VerticalInPlaceCarousel = ({
  data: { id, heading, subheading, items },
  stripePlan = null,
  setShow
}) => {
  const authContext = useAuthContext();
  const [activeSlide, setActiceSlideIndex] = useState();
  // const [prevActiveSlide, setPrevActiceSlideIndex] = useState();
  /* const [backgroundImages, setBackgroundImages] = useState([]);
  const [backgroundImagesMobile, setBackgroundImagesMobile] = useState([]);
  const bgImages = items.map((item) =>
    item.backgroundImage ? item.backgroundImage.fluid.src : ""
  );
  const bgImagesMobile = items.map((item) =>
    item.backgroundImageMobile ? item.backgroundImageMobile.fluid.src : ""
  );
  bgImages.unshift([bgImages[0]]);
  bgImagesMobile.unshift([bgImagesMobile[0]]);*/

  useEffect(() => {
    const transformBlock = Array.from(
      document.getElementsByClassName("items-transport")
    );
    const imageWrappers = document.querySelectorAll(".scrolling-image-wrapper");
    const bgSlidesWraper = document.querySelectorAll(".bgSlidesWraper");
    const parentWraper = document.querySelector(".fitnes-transform-slide");
    const bgSlideWrap = document.querySelector(".background-slide-fitness");
    const w = window.innerWidth;
    const h = window.innerHeight;
    let slideNo;
    window.onscroll = function (e) {
      const transformTop = transformBlock.map(
        (transformBlock1) => transformBlock1.getBoundingClientRect().top
      );
      const parentPosition = parentWraper.getBoundingClientRect();
      if (parentPosition.top <= 0 && parentPosition.bottom >= h) {
        bgSlideWrap.classList.add("active");
        bgSlideWrap.classList.remove("bottom-pos");
        bgSlideWrap.classList.remove("top-pos");
      } else {
        bgSlideWrap.classList.remove("active");
        if (parentPosition.bottom <= h) {
          bgSlideWrap.classList.add("bottom-pos");
        }
        if (parentPosition.top > 0) {
          bgSlideWrap.classList.add("top-pos");
        }
      }

      transformTop.forEach((transform, index) => {
        if (transform >= 19 && transform >= -40 && index <= 1) {
          imageWrappers.forEach((imageWrapper) => {
            imageWrapper.classList.remove("active");
          });
          bgSlidesWraper.forEach((bgSlide) => {
            bgSlide.classList.remove("active");
          });

          imageWrappers.length > 0 && imageWrappers[0].classList.add("active");
          bgSlidesWraper.length > 0 &&
            bgSlidesWraper[0].classList.add("active");
          setActiceSlideIndex(index);
        } else if (transform <= 20 && transform >= -40) {
          if (index === 1) {
            imageWrappers[0].classList.add("active");
            bgSlidesWraper[0].classList.add("active");
            setActiceSlideIndex(index);
          } else {
            imageWrappers.forEach((imageWrapper) => {
              imageWrapper.classList.remove("active");
            });
            bgSlidesWraper.forEach((bgSlide) => {
              bgSlide.classList.remove("active");
            });
            slideNo = index - 1;
            imageWrappers[slideNo]?.classList.add("active");
            bgSlidesWraper[slideNo]?.classList.add("active");
            setActiceSlideIndex(index);
          }
        }
      });
    };
    /* setBackgroundImages(bgImages);
    setBackgroundImagesMobile(bgImagesMobile);*/
  }, []);

  // useEffect(() => { if(activeSlide !== prevActiveSlide) {
  //   setPrevActiceSlideIndex(activeSlide);
  // } }, [activeSlide])

  const linkHelpText = (text) => {
    if (stripePlan) {
      const amount = stripePlan.amount / 100;
      const currencySymbol = getSymbolFromCurrency(
        stripePlan.currency.toUpperCase()
      );
      const updatedText = text
        .replace("$15.99", `${currencySymbol}${amount}`)
        .replace("$MONTHLYCOST", `${currencySymbol}${amount}`);
      return <span className="monthly-plan">{updatedText}</span>;
    }
    return <span className="monthly-plan">{text}</span>;
  };

  return (
    <section className="fitnes-transform-slide">
      <div className={`desktop-slide`}>
        <ul className="background-slide-fitness">
          {items &&
            items.map((item, key) => (
              <li className="bgSlidesWraper" key={key}>
                <GatsbyImage
                  image={getImage(item.backgroundImage)}
                  alt={item.heading}
                />
              </li>
            ))}
        </ul>
        <h3 className="scrolling-title">{heading}</h3>
        <p className="scrolling-description">{subheading}</p>
        <div className="scrolling-container container">
          <div className="scrolling-items">
            <div className="transfrom-details items-transport"></div>
            {items?.map((item) => (
              <div
                key={`transfrom-details-${item.id}`}
                className="transfrom-details items-transport"
              >
                <h4>{item.heading}</h4>
                <p>{item.body}</p>
                <div className="button-wrapper">
                  {setShow && item.link === "CUSTOM_PURCHASE_FLOW" && (
                    <CTAButton onClick={() => setShow(true)}>{item.linkText}</CTAButton>
                  )}

                  {item.link && item.link !== "CUSTOM_PURCHASE_FLOW" && (
                    <Link
                      to={
                        authContext.loggedIn
                          ? item.branchLink ?? item.branchLink
                          : item.link
                      }
                      className="common-button"
                    >
                      {item.linkText}
                    </Link>
                  )}
                  {item.linkHelpText && linkHelpText(item.linkHelpText)}
                </div>
              </div>
            ))}
          </div>
          <div className="scrolling-block">
            <div className="scrolling-image">
              {items?.map((item) => (
                <div
                  key={`scrolling-image-wrapper-${item.id}`}
                  className="scrolling-image-wrapper"
                >
                  <GatsbyImage
                    image={getImage(item.desktopImage)}
                    alt={item.heading}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-slide">
        <Swiper
          pagination={{
            clickable: true,
          }}
        >
          {items &&
            items.map((item, key) => (
              <SwiperSlide
                key={`fitness-slide-${key}`}
                className={`fitness-slide`}
              >
                <div className={`fitnes-transform slide-${key}`} style={{}}>
                  <div className="fitnes-transform-overlay">
                    <div className="container">
                      <h3 className="fitnes-transform-title">{heading}</h3>
                      <p className="fitnes-transform-description">
                        {subheading}
                      </p>
                      <div className="image-section">
                        <div className="transfrom-details">
                          <h4>{item.heading}</h4>
                          <p>{item.body}</p>
                          <div className="button-wrapper">
                            {item.link && (
                              <Link to={item.link} className="common-button">
                                {item.linkText}
                              </Link>
                            )}
                            {item.linkHelpText && (
                              <span className="monthly-plan">
                                {item.linkHelpText}
                              </span>
                            )}
                          </div>
                        </div>
                        <GatsbyImage
                          image={getImage(item.desktopImage)}
                          alt={item.heading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </section>
  );
};

export default VerticalInPlaceCarousel;

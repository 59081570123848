import React from "react";
//import { Link } from "gatsby";
import { Link } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import pauseImage from "../../images/icons/pause-icon.png";
import playImage from "../../images/icons/play-icon.png";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import { isValidHttpUrl } from "../../lib/UrlUtils";
import "./twoColumnTextVideoBlock.scss";
import FitplanLink from "../FitplanLink";
import { CTAButton } from "../../lib/components/Button/CTAButton";
import VideoPlayer from "../videoPlayer/videoPlayer";

const TwoColumnTextVideoBlock = ({ data: { items }, rounded, setShow }) => {
  const authContext = useAuthContext();
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClass(node)}>{children}</p>
      ),
    },
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  function paragraphClass(node) {
    const className = "odd";
    //alternate logic for 'odd' | 'even'
    return className;
  }

  let roundClass = "";
  if (rounded) {
    roundClass = "rounded-video";
  }

  return (
    <>
      {items?.map((item) => (
        <section key={item.id} className="wecare-sec">
          <div className="container">
            <div className="col-6 text-section">
              <h4>{item.heading}</h4>
              {item.content && renderRichText(item.content, options)}
              <p>{item.body}</p>
              <div className="button-wrapper">
                {setShow && item.link === "CUSTOM_PURCHASE_FLOW" && (
                  <CTAButton onClick={() => setShow(true)}>{item.linkText}</CTAButton>
                )}
                {item.link && item.link !== "CUSTOM_PURCHASE_FLOW" && (
                  authContext.loggedIn ? <FitplanLink link={item.branchLink} linkText={item.linkText} /> : <FitplanLink link={item.link} linkText={item.linkText} />
                )}
                {item.linkHelpText && (
                  <span className="monthly-plan">{item.linkHelpText}</span>
                )}
              </div>
            </div>
            <div className="col-6 video-col">
              <VideoPlayer videoId={item.id} videoItem={item.video} videoHeading={item.heading} videoThumbnail={item.videoThumbnail} videoClass={roundClass} />
            </div>
          </div>
        </section>
      ))}
    </>
  );
};

export default TwoColumnTextVideoBlock;

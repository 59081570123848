import React from "react";
//import { Link } from "gatsby";
import { Link } from "gatsby-plugin-react-i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import parse from "html-react-parser";
import "./textBlockWithBackgroundImage.scss";
import { CTAButton } from "../../lib/components/Button/CTAButton";

const TextBlockWithBackgroundImage = ({ data: { items }, setShow }) => {
  const authContext = useAuthContext();
  return (
    <>
      {items?.map((item) => (
        <section key={item.id} className="become-trainer">
          {item.desktopImage && (
            <GatsbyImage
              image={getImage(item.desktopImage)}
              className="web"
              alt={item.heading}
            />
          )}
          {item.mobileImage && (
            <GatsbyImage
              image={getImage(item.mobileImage)}
              className="mob"
              alt={item.heading}
            />
          )}
          <div className="become-trainer-overlay">
            <div className="container">
              <div className="text-section">
                {item.heading && (
                  <h5 className="trainer-title">{parse(item.heading)}</h5>
                )}
                {item.subheading && (
                  <p className="trainer-description">{item.subheading}</p>
                )}
                {setShow && item.link === "CUSTOM_PURCHASE_FLOW" && (
                  <CTAButton onClick={() => setShow(true)}>{item.linkText}</CTAButton>
                )}
                {item.link && item.link !== "CUSTOM_PURCHASE_FLOW" && (
                  <Link
                    to={
                      authContext.loggedIn
                        ? item.branchLink ?? item.branchLink
                        : item.link
                    }
                    className="start-now"
                  >
                    {item.linkText}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </section>
      ))}
    </>
  );
};

export default TextBlockWithBackgroundImage;

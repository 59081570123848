import React, { useState } from "react";
import pauseImage from "../../images/icons/pause-icon.png";
import playImage from "../../images/icons/play-icon.png";
import { isValidHttpUrl } from "../../lib/UrlUtils";
import videoPlaceholder from "../../images/video-placeholder-blank.svg";
import "./videoPlayer.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const VideoPlayer = ({
  videoId,
  videoThumbnail,
  videoItem,
  videoHeading,
  videoClass = null,
}) => {
  const [showThumbnail, toggleThumbnail] = useState(true);
  const playVideo = (videoIframeId) => {
    toggleThumbnail(false);
    const player = document.getElementById("video-" + videoIframeId);
    const data = { method: "play" };
    if (player.contentWindow) {
      player.contentWindow.postMessage(JSON.stringify(data), "*");
    } else {
      player.play();
    }

    const col = document.getElementById("video-col-" + videoIframeId);
    const playButton = document.getElementById(
      "video-control-play-" + videoIframeId
    );
    const pauseButton = document.getElementById(
      "video-control-pause-" + videoIframeId
    );

    playButton.style.opacity = "0";
    playButton.style.visibility = "hidden";

    setTimeout(() => {
      pauseButton.style.opacity = "0";
      pauseButton.style.transitionDuration = "0.5s";
      pauseButton.style.visibility = "hidden";
      col.classList.add("active");
    }, 100);
  };

  const pauseVideo = (videoIframeId) => {
    const player = document.getElementById("video-" + videoIframeId);
    const data = { method: "pause" };
    if (player.contentWindow) {
      player.contentWindow.postMessage(JSON.stringify(data), "*");
    } else {
      player.pause();
    }

    const col = document.getElementById("video-col-" + videoIframeId);
    const playButton = document.getElementById(
      "video-control-play-" + videoIframeId
    );
    const pauseButton = document.getElementById(
      "video-control-pause-" + videoIframeId
    );

    col.classList.remove("active");
    pauseButton.style.display = "none";
    setTimeout(() => {
      playButton.style.visibility = "visible";
      playButton.style.opacity = "9";
      playButton.style.transitionDuration = "0.2s";
    }, 100);
  };

  const focusItem = (videoIframeId, action) => {
    const col = document.getElementById("video-col-" + videoIframeId);
    const playButton = document.getElementById(
      "video-control-play-" + videoIframeId
    );
    const pauseButton = document.getElementById(
      "video-control-pause-" + videoIframeId
    );

    if (col.classList.contains("active")) {
      if (action === 1) {
        playButton.style.opacity = "0";
        pauseButton.style.display = "block";
        setTimeout(() => {
          pauseButton.style.opacity = "9";
          pauseButton.style.transitionDuration = "0.5s";
          pauseButton.style.zIndex = "0";
          pauseButton.style.visibility = "visible";
        }, 100);
      } else {
        pauseButton.style.opacity = "0";
        playButton.style.opacity = "0";
      }
    }
  };

  const showPauseButton = (videoId) => {
    const col = document.getElementById("video-col-" + videoId);
    const pauseButton = document.getElementById(
      "video-control-pause-" + videoId
    );
    const playButton = document.getElementById("video-control-play-" + videoId);
    if (col.classList.contains("active")) {
      playButton.style.opacity = "0";
      pauseButton.style.display = "block";
      setTimeout(() => {
        pauseButton.style.opacity = "9";
        pauseButton.style.transitionDuration = "0.5s";
        pauseButton.style.zIndex = "0";
        pauseButton.style.visibility = "visible";
      }, 100);
    }
  };

  return (
    <div
      id={`video-col-${videoId}`}
      onMouseEnter={() => focusItem(videoId, 1)}
      onTouchEnd={() => showPauseButton(videoId)}
      onMouseLeave={() => focusItem(videoId, 0)}
      className={`video-player`}
    >
      <div className={`video-container ${videoClass}`}>
        {(showThumbnail && videoThumbnail) && <GatsbyImage
          className="video-thumbnail"
          image={getImage(videoThumbnail)}
          alt={`Thumbnail for video - ${videoHeading}`}
        />}
        {videoId ? (
          <>
            {videoItem?.length > 0 &&
              (!isValidHttpUrl(videoItem) ? (
                <iframe
                  id={`video-${videoId}`}
                  title={videoHeading}
                  src={`https://player.vimeo.com/video/${videoItem}?api=1&loop=1&controls=0`}
                  frameBorder="0"
                  allow="autoplay; fullscreen;"
                  allowFullScreen
                  loading="lazy"
                ></iframe>
              ) : (
                <div className="video-player-container">
                  <video
                    id={`video-${videoId}`}
                    playsInline
                    className="video-player"
                  >
                    <source src={`${videoItem}#t=0.7`} type="video/mp4" />
                  </video>
                </div>
              ))}
          </>
        ) : (
          ""
        )}
      </div>
      <div className="controlled-buttons" onClick={() => focusItem(videoId)}>
        <span
          id={`video-control-play-${videoId}`}
          className="play-button"
          onClick={() => playVideo(videoId)}
        >
          <picture>
            <source media="(max-width: 799px)" srcSet={playImage} />
            <img src={playImage} alt="Play Button" />
          </picture>
        </span>
        <span
          id={`video-control-pause-${videoId}`}
          className="pause-button"
          onClick={() => pauseVideo(videoId)}
        >
          <picture className="d-none">
            <source media="(max-width: 799px)" srcSet={pauseImage} />
            <img src={pauseImage} alt="Pause Button" />
          </picture>
        </span>
      </div>
    </div>
  );
};

export default VideoPlayer;

import React, { useState } from "react";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import "./faqBottom.scss";

const FaqBottom = ({ data: { heading, subheading, items } }) => {
  const Bold = ({ children }) => <span className="bold">{children}</span>;
  const Text = ({ children }) => <p className="align-center">{children}</p>;

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <>
            <h2>Embedded Asset</h2>
            <pre>
              <code>{JSON.stringify(node, null, 2)}</code>
            </pre>
          </>
        );
      },
    },
  };

  return (
    <>
      <section className="fitplan-faq-section">
        <div className="container">
          <h2 className="fit-title">{heading}</h2>
          <p className="fit-description">{subheading}</p>
        </div>
        <div className="faq-section-wrap">
          {items.map(
            (item, key) =>
              item.heading && (
                <FaqAccordion
                  key={key}
                  heading={item.heading}
                  content={
                    item.content && renderRichText(item.content, options)
                  }
                />
              )
          )}
        </div>
        <div className="container container-faq-line">
          <hr className="faq-line" />
        </div>
      </section>
    </>
  );
};

const FaqAccordion = ({ heading, content }) => {
  const [isActive, setIsActive] = useState(false);
  let activeClass = "";
  if (isActive) {
    activeClass = "active";
  }
  return (
    <article className={`single-faq ${activeClass}`}>
      <div className="title-faq-wrap">
        <h2 className="title-faq" onClick={() => setIsActive(!isActive)}>
          {heading}
        </h2>
      </div>
      <div className="content-faq-wrap">
        <div className="content-faq">{content}</div>
      </div>
    </article>
  );
};

export default FaqBottom;

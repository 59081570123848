import React, { useEffect, useRef } from "react";
import { Link } from "gatsby-plugin-react-i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import parse from "html-react-parser";
import { gsap } from "gsap";
import Slider from "react-slick";
import ScrollAnimIcon from "../scrollAnimationIcon/scrollAnimationIcon";
import { getSymbolFromCurrency } from "../../global/currency";

import "./fullPageHorizontalCarousel.scss";
import FitplanLink from "../FitplanLink";
import { CTAButton } from "../../lib/components/Button/CTAButton";

const FullPageHorizontalCarousel = ({ data, stripePlan, setShow }) => {
  const { items } = data;
  const authContext = useAuthContext();
  //const [animation, setAnimation] = useState();

  let bannerText = useRef(null);
  let bannerButton = useRef(null);
  let scrollAnim = useRef(null);

  let bannerHeader = useRef(null);

  const settings = {
    dots: false,
    fade: true,
    arrows: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
  };

  useEffect(() => {
    var tl = gsap.timeline({ duration: 0.7 });
    tl.from(bannerButton.current, { duration: 0.75, y: 50, opacity: 0 }, "load")
      .from(bannerText.current, { duration: 0.75, y: 50, opacity: 0 }, "load")
      .from(bannerHeader.current, { duration: 0.75, y: 130 }, "-=.3")
      .from(scrollAnim.current, { duration: 0.25, y: 30, opacity: 0 }, "+=1");
  }, []);

  /* Stripe Pricing Logic */
  let amount = stripePlan?.amount;
  let linkHelpText = items && items[0]?.linkHelpText;
  if (stripePlan?.amount) {
    amount = amount / 100;
    let currencySymbol = getSymbolFromCurrency(
      stripePlan.currency.toUpperCase()
    );
    linkHelpText =
      linkHelpText &&
      linkHelpText?.replace("$15.99", `${currencySymbol}${amount}`);
    linkHelpText?.replace("$MONTHLYCOST", `${currencySymbol}${amount}`);
  }

  return (
    <>
      <section className="home-hero">
        <div className="slick-slider-home-wrap">
          <Slider {...settings}>
            {items?.map((item) => {
              const desktopImage = getImage(item.desktopImage);
              const mobileImage = getImage(item.mobileImage);
              return (
                <div className="fit-slide" key={item.id}>
                  {desktopImage && <GatsbyImage
                    image={desktopImage}
                    className="web"
                    alt="home"
                  />}
                  {mobileImage && <GatsbyImage image={mobileImage} className="mob" alt="home" />}
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="home-hero-over-layer">
          <div className="container">
            <h1 className="hero-title">
              {items && items[0].heading && (
                <span ref={bannerHeader}>{parse(items[0].heading)}</span>
              )}
            </h1>
            <p className="hero-description" ref={bannerText}>
              {items && items[0].subheading && parse(items[0].subheading)}
            </p>
            <div className="button-wrapper" ref={bannerButton}>
              {/*               
              {setShow && !authContext.loggedIn && items[0].link === "CUSTOM_PURCHASE_FLOW" && (
                <CTAButton onClick={() => setShow(true)}>{items[0].linkText}</CTAButton>
              )}
              {!setShow && items && items[0].link && !authContext.loggedIn && (
                <FitplanLink link={items[0].link} linkText={items[0].linkText} />
              )}
              {!setShow && items && items[0].branchLink && authContext.loggedIn && (
                <FitplanLink link={items[0].branchLink} linkText={items[0].linkText} />
              )}
               */}
              {setShow && items[0].link === "CUSTOM_PURCHASE_FLOW" ? (
                <CTAButton onClick={() => setShow(true)}>{items[0].linkText}</CTAButton>
              ) : (!setShow && items && items[0].branchLink && (
                <FitplanLink link={items[0].branchLink} linkText={items[0].linkText} />
              ))}
              {items && items[0].linkHelpText && (
                <span className="monthly-plan">{linkHelpText}</span>
              )}
            </div>
            <div ref={scrollAnim}>
              <ScrollAnimIcon />
            </div>
          </div>
        </div>

        {/* */}
      </section>
    </>
  );
};

export default FullPageHorizontalCarousel;

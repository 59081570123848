import React, { useState } from "react";
import { Link } from "gatsby";
// import { Link } from "gatsby-plugin-react-i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Controller } from "swiper/core";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import "swiper/swiper.min.css";
import "./productCarouselBottom.scss";
import { StaticImage } from "gatsby-plugin-image";

SwiperCore.use([Pagination, Navigation, Controller]);
const ProductCarouselBottom = ({ data: { heading, subheading, items } }) => {
  const authContext = useAuthContext();
  const [controlSwiper, setControlSwiper] = useState(null);

  const onNextClick = () => {
    controlSwiper.slideNext(500);
  };

  const onPreviousClick = () => {
    controlSwiper.slidePrev(500);
  };

  return (
    <>
      <section className="fitplan-product-slide-section">
        <div className="container">
          <h2 className="fit-title">{heading}</h2>
          <p className="fit-description">{subheading}</p>

          <div className="slider-button-wrap">
            <button
              type="button"
              onClick={onPreviousClick}
              className="prev-icon"
            >
              <StaticImage
                src="../../images/icons/left-arrow.png"
                alt="pevious"
                placeholder="blurred"
              />
            </button>

            <button type="button" onClick={onNextClick} className="next-icon">
              <StaticImage
                src="../../images/icons/right-arrow.png"
                alt="pevious"
                placeholder="blurred"
              />
            </button>
          </div>
        </div>
        <div className="slider-section">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={20}
            centeredSlides={true}
            loop={true}
            loopFillGroupWithBlank={true}
            pagination={false}
            onSwiper={setControlSwiper}
            controller={{ control: controlSwiper }}
            breakpoints={{
              575: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            className="mySwiper"
          >
            {items.map((item, key) => {
              return (
                <SwiperSlide className="relative" item={item} key={item.id}>
                  <Link
                    to={item.link ?? "#"}
                  >
                    <div className="hover-fitsec-image">
                      <GatsbyImage
                        image={getImage(item.desktopImage)}
                        alt={item.heading}
                      />
                    </div>

                    <div className="slider-data">
                      <h3 className="slider-data-title">{item.heading}</h3>
                      {item.subheading && (
                        <h4 className="sub-title">{item.subheading}</h4>
                      )}
                      {item.body && <p className="offer-prod">{item.body}</p>}
                    </div>
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default ProductCarouselBottom;

import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import striptags from "striptags";
import "./twoColumnBlockWithImagesOnly.scss";

const TwoColumnBlockWithImagesOnly = ({ data }) => {
  const {
    backgroundImage: backgroundImage1,
    backgroundImageMobile: backgroundImage2,
    desktopImage: image1,
    mobileImage: image2,
    heading,
    subheading,
    id,
  } = data.items[0];

  return (
    <section id={id} className="differentTypeofworkout">
      <div className="container">
        {heading && <h3 className="title">{parse(heading)}</h3>}
        {subheading && <p className="description">{parse(subheading)}</p>}
        <div className="two-way-col">
          <div className="colums">
            <GatsbyImage
              image={getImage(backgroundImage1)}
              alt={striptags(heading)}
              placeholder="blurred"
              layout="constrained"
            />
            <div className="abs-left-image">
              <GatsbyImage
                image={getImage(image1)}
                alt={striptags(heading)}
                placeholder="blurred"
                layout="constrained"
              />
            </div>
          </div>
          <div className="colums">
            <GatsbyImage
              image={getImage(backgroundImage2)}
              alt={striptags(heading)}
              placeholder="blurred"
              layout="constrained"
            />
            <div className="abs-right-image">
              <GatsbyImage
                image={getImage(image2)}
                alt={striptags(heading)}
                placeholder="blurred"
                layout="constrained"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TwoColumnBlockWithImagesOnly;

import { Link } from 'gatsby';
import { Link as TLink } from 'gatsby-plugin-react-i18next';
import React from 'react'
import { isValidHttpUrl } from '../../lib/UrlUtils'

const FitplanLink = ({ link, linkText, cssClass="common-button" }) => {
    if (isValidHttpUrl(link)) {
        return <Link className={cssClass} to={link}>{linkText}</Link>
    } else {
        return <TLink className={cssClass} to={`/${link}`}>{linkText}</TLink>
    }
}

export default FitplanLink
import React from "react";
import { BlockTypes } from "../lib/block-types";
import FullPageHorizontalCarousel from "./fullPageHorizontalCarousel/fullPageHorizontalCarousel";
import SmallCellHorizontalCarousel from "./smallCellHorizontalCarousel/smallCellHorizontalCarousel";
import VerticalInPlaceCarousel from "./verticalInPlaceCarousel/verticalInPlaceCarousel";
import TwoColumnTextImageBlock from "./twoColumnTextImageBlock/twoColumnTextImageBlock";
import SmallCellHorizontalBlock from "./smallCellHorizontalBlock/smallCellHorizontalBlock";
import TextBlockWithBackgroundImage from "./textBlockWithBackgroundImage/textBlockWithBackgroundImage";
import TwoColumnTextVideoBlock from "./twoColumnTextVideoBlock/twoColumnTextVideoBlock";
import ProductCarouselBottom from "./productCarouselBottom/productCarouselBottom";
import TrainerTestimonial from "./trainerTestimonial/trainerTestimonial";
import FaqBottom from "./faqBottom/faqBottom";
import AboutTrainer from "./aboutTrainer/aboutTrainer";
import TwoColumnImageTextBlock from "./twoColumnImageTextBlock/twoColumnImageTextBlock";
import TwoColumnContentRichImageBlock from "./twoColumnContentRichImageBlock/twoColumnContentRichImageBlock";
import TrainerListBlock from "./trainerListBlock/trainerListBlock";
import PlanListBlock from "./planListBlock/planListBlock";
import PlanBasicBlock from "./planBasicBlock/planBasicBlock";
import TextOnlyBlock from "./textOnlyBlock/textOnlyBlock";
import PlansSmallCellHorizontalCarousel from "./plansSmallCellHorizontalCarousel/plansSmallCellHorizontalCarousel";
import StaticHeroBlockWithBackgroundImage from "./staticHeroBlockWithBackgroundImage/staticHeroBlockWithBackgroundImage";
import StaticFooterBlockWithBackgroundImage from "./staticFooterBlockWithBackgroundImage/staticFooterBlockWithBackgroundImage";
import TwoColumnBlockWithImagesOnly from "./twoColumnBlockWithImagesOnly/twoColumnBlockWithImagesOnly";

const TypeToBlock = ({ module, stripePlanMonthly, setShow }) => {
  const {
    fullPageHorizontalCarousel,
    smallCellHorizontalCarousel,
    verticalInPlaceCarousel,
    twoColumnTextImageBlock,
    twoColumnTextVideoBlock,
    smallCellHorizontalBlock,
    textBlockWithBackgroundImage,
    twoColumnImageTextBlock,
    twoColumnContentRichImageBlock,
    aboutTrainerBlock,
    trainerGearBlock,
    trainerTestimonialBlock,
    faqBlock,
    trainerListBlock,
    planListBlock,
    planBasicInfoBlock,
    plansSmallCellHorizontalCarousel,
    twoColumnTextVideoRoundedBlock,
    textOnlyBlock,
    staticHeroBlockWithBackgroundImage,
    staticFooterBlockWithBackgroundImage,
    twoColumnBlockWithImagesOnly,
  } = BlockTypes;

  switch (module.type) {
    case fullPageHorizontalCarousel:
      return (
        <FullPageHorizontalCarousel
          stripePlan={stripePlanMonthly}
          data={module} setShow={setShow}
        />
      );
    case smallCellHorizontalCarousel:
      return <SmallCellHorizontalCarousel data={module} setShow={setShow} />;
    case verticalInPlaceCarousel:
      return (
        <VerticalInPlaceCarousel stripePlan={stripePlanMonthly} data={module} setShow={setShow} />
      );
    case twoColumnTextImageBlock:
      return <TwoColumnTextImageBlock data={module} setShow={setShow} />;
    case twoColumnTextVideoBlock:
      return <TwoColumnTextVideoBlock data={module} setShow={setShow} />;
    case twoColumnTextVideoRoundedBlock:
      return <TwoColumnTextVideoBlock data={module} setShow={setShow} rounded="true" />;
    case smallCellHorizontalBlock:
      return <SmallCellHorizontalBlock data={module} setShow={setShow} />;
    case textBlockWithBackgroundImage:
      return <TextBlockWithBackgroundImage data={module} setShow={setShow} />;
    case twoColumnImageTextBlock:
      return <TwoColumnImageTextBlock data={module} setShow={setShow} />;
    case twoColumnContentRichImageBlock:
      return <TwoColumnContentRichImageBlock data={module} setShow={setShow} />;
    case aboutTrainerBlock:
      return <AboutTrainer data={module} setShow={setShow} />;
    case trainerGearBlock:
      return <ProductCarouselBottom data={module} setShow={setShow} />;
    case trainerTestimonialBlock:
      return <TrainerTestimonial data={module} setShow={setShow} />;
    case faqBlock:
      return <FaqBottom data={module} setShow={setShow} />;
    case trainerListBlock:
      return <TrainerListBlock data={module} setShow={setShow} />; // trainers list page
    case planListBlock:
      return <PlanListBlock data={module} setShow={setShow} />; // fitplan list page
    case planBasicInfoBlock:
      return <PlanBasicBlock data={module} setShow={setShow} />;
    case plansSmallCellHorizontalCarousel:
      return <PlansSmallCellHorizontalCarousel data={module} setShow={setShow} />;
    case textOnlyBlock:
      return <TextOnlyBlock data={module} setShow={setShow} />;
    case staticHeroBlockWithBackgroundImage:
      return <StaticHeroBlockWithBackgroundImage data={module} setShow={setShow} />;
    case staticFooterBlockWithBackgroundImage:
      return <StaticFooterBlockWithBackgroundImage data={module} setShow={setShow} />;
    case twoColumnBlockWithImagesOnly:
      return <TwoColumnBlockWithImagesOnly data={module} setShow={setShow} />;
    default:
      return true;
  }
};

export default TypeToBlock;

import React, { useState } from "react";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import parse from "html-react-parser";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import "./trainerTestimonial.scss";

const TrainerTestimonial = ({ data: { heading, subheading, items } }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClass(node)}>{children}</p>
      ),
    },
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };
  function paragraphClass(node) {
    const className = "odd";
    //alternate logic for 'odd' | 'even'
    return className;
  }

  return (
    <section className="trainer-testimonial">
      <div className="container">
        {heading && <h4 className="real-people-title">{parse(heading)}</h4>}
        {subheading && <p className="real-people-description">{subheading}</p>}
        {items.map((item, key) => {
          return (
            <div key={key} className="real-people-test-wrap">
              <div className="right-test-image">
                <GatsbyImage
                  image={getImage(item.desktopImage)}
                  alt={item.heading ? item.heading : ""}
                />
              </div>
              <div className="left-test-trainer">
                <svg
                  className="quote"
                  viewBox="0 0 37 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.0332 16.5737H14.4531V31.0269H0V20.0894C0 8.14273 4.81771 1.74625 14.4531 0.899902V6.31982C10.8398 7.32894 9.03321 10.2261 9.03321 15.0112L9.0332 16.5737ZM30.7129 16.5737H36.1328V31.0269H21.6797V20.0894C21.6797 8.14273 26.4974 1.74625 36.1328 0.899902V6.31982C32.5195 7.32894 30.7129 10.2261 30.7129 15.0112L30.7129 16.5737Z"
                    fill="#ACB0B4"
                  />
                </svg>

                <div className="text">
                  {item.content && renderRichText(item.content, options)}
                </div>
                <div className="people-detail">
                  <h5>
                    {item.heading}
                    <span>{item.subheading}</span>
                  </h5>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default TrainerTestimonial;

import styled from "@emotion/styled";

export const CTAButton = styled.div`
color: white;
font-family: Barlow Condensed;
font-weight: 600;
font-size: 21px;
cursor: pointer;
min-width: 240px;
height: 48px;
display: block;
text-transform: uppercase;
text-align: center;
border-radius: 20px;
padding: 11px 15px;
background: linear-gradient(225deg, #29db57 0%, #12b587 100%);
box-shadow: 0px 2px 4px rgba(17, 18, 19, 0.101961);
font-display: swap;
font-size: 21px;
line-height: 22px;
`;
import React, { useState, useEffect } from "react";
import { Link } from "gatsby-plugin-react-i18next";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Controller } from "swiper/core";
import "swiper/swiper.min.css";
import "./plansSmallCellHorizontalCarousel.scss";

SwiperCore.use([Pagination, Navigation, Controller]);
const PlansSmallCellHorizontalCarousel = ({
  data: { id, heading, subheading, items },
}) => {
  const [controlSwiper, setControlSwiper] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);

  const onNextClick = () => {
    controlSwiper.slideNext(500);
  };

  const onPreviousClick = () => {
    controlSwiper.slidePrev(500);
  };

  useEffect(() => {
    let filteredItemsTemp = items.filter(item => item.planName);
    setFilteredItems(filteredItemsTemp)
  }, [items])

  useEffect(() => {
    if (filteredItems) {
      const parent = document.getElementById(`pshzc-${id}`);
      if (filteredItems.length < 3 || filteredItems.length === 3) {
        parent.querySelector(".prev-icon").classList.add("disable");
        parent.querySelector(".next-icon").classList.add("disable");
        parent.querySelector(".swiper-wrapper").classList.add("custom_field");
      } else {
        parent.querySelector(".prev-icon").classList.remove("disable");
        parent.querySelector(".next-icon").classList.remove("disable");
        parent
          .querySelector(".swiper-wrapper")
          .classList.remove("custom_field");
      }

      if (filteredItems.length === 3) {
        parent.querySelector('.custom_field').classList.add('three-slides');
      }
      if (filteredItems.length === 2) {
        parent.querySelector('.custom_field').classList.add('two-slides');
      }
      if (filteredItems.length === 1) {
        parent.querySelector('.custom_field').classList.add('one-slides');
      }
    }
  }, [filteredItems]);

  return (
    <section key={`pshzc-${id}`} id={`pshzc-${id}`} className="fitplan-section-plans-carousel">
      <div className="container">
        <h2 className="fit-title">{heading}</h2>
        <p className="fit-description">{subheading}</p>
      </div>
      <div className="slider-section">
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={20}
          //centeredSlides={true}
          //centeredSlidesBounds= {true}
          loop={filteredItems.length > 3 ? true : false}
          loopFillGroupWithBlank={filteredItems.length > 3 ? true : false}
          pagination={false}
          onSwiper={setControlSwiper}
          controller={{ control: controlSwiper }}
          breakpoints={{
            575: {
              slidesPerView: 2,
              spaceBetween: 20,

            },
            992: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 30,

            },
          }}
          className="mySwiper"
        >
          <button type="button" onClick={onPreviousClick} className="prev-icon">
            <StaticImage
              src="../../images/icons/left-arrow.png"
              alt="pevious"
              placeholder="blurred"
            />
          </button>

          {filteredItems?.map((item) => {
            return (item.planName && getImage(item.featureImage)) && (
              <SwiperSlide className="relative" item={item} key={item.id}>
                <Link to={item.planSlug ? `/plans/${item.planSlug}` : "#"}>
                  <div className="hover-fitsec-image">
                    <GatsbyImage
                      image={getImage(item.featureImage)}
                      alt={item.planName}
                    />
                  </div>

                  <div className="slider-data">
                    <h3 className="slider-data-title">{item.planName}</h3>
                    {/*{item.shortDescription && (
                        <h4 className="sub-title">{item.shortDescription}</h4>
                      )}*/}
                    {item.shortDescription && (
                      <p className="work-days">{item.shortDescription}</p>
                    )}
                  </div>
                </Link>
              </SwiperSlide>
            );
          })}

          <button type="button" onClick={onNextClick} className="next-icon">
            <StaticImage
              src="../../images/icons/right-arrow.png"
              alt="pevious"
              placeholder="blurred"
            />
          </button>
        </Swiper>
      </div>
    </section>
  );
};

export default PlansSmallCellHorizontalCarousel;

import React from "react";
//import { Link } from "gatsby";
import { Link } from "gatsby-plugin-react-i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import parse from "html-react-parser";
import "./twoColumnImageTextBlock.scss";
import FitplanLink from "../FitplanLink";
import { CTAButton } from "../../lib/components/Button/CTAButton";
const TwoColumnImageTextBlock = ({ data: { items }, setShow }) => {
  const authContext = useAuthContext();
  return (
    <>
      {items?.map((item) => (
        <section className="multiple-device" key={item.id}>
          <div className="device-details">
            <div className="text-section">
              {item.heading && <h4>{parse(item.heading)}</h4>}
              <p>{item.body}</p>
              <div className="button-wrapper">
                {setShow && item.link === "CUSTOM_PURCHASE_FLOW" && (
                  <CTAButton onClick={() => setShow(true)}>{item.linkText}</CTAButton>
                )}
                {item.link && item.link !== "CUSTOM_PURCHASE_FLOW" && (
                  authContext.loggedIn ? <FitplanLink link={item.branchLink} linkText={item.linkText} /> :  <FitplanLink link={item.link} linkText={item.linkText} />
                )}
                {item.linkHelpText && (
                  <span className="monthly-plan">{item.linkHelpText}</span>
                )}
              </div>
            </div>
          </div>
          <div className="device-image">
            <GatsbyImage
              image={getImage(item.desktopImage)}
              alt={item.heading}
            />
          </div>
        </section>
      ))}
    </>
  );
};

export default TwoColumnImageTextBlock;

import React, { useEffect } from "react";
//import { Link } from "gatsby";
import { Link } from "gatsby-plugin-react-i18next";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./twoColumnContentRichImageBlock.scss";
import FitplanLink from "../FitplanLink";
import { CTAButton } from "../../lib/components/Button/CTAButton";

const TwoColumnContentRichImageBlock = ({ data: { items, flipBlocks }, setShow }) => {
  const authContext = useAuthContext();
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClass(node)}>{children}</p>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => {
        return <li>{node.content[0].content[0].value}</li>;
      },
    },
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  function paragraphClass(node) {
    const className = "odd";
    return className;
  }

  const TextBlock = ({ item, className = "" }) => (
    <div className={`col-6 text-section ${className}`}>
      <h4>{item.heading}</h4>
      {item.content && renderRichText(item.content, options)}
      <div className="button-wrapper">
        {setShow && item.link === "CUSTOM_PURCHASE_FLOW" && (
          <CTAButton onClick={() => setShow(true)}>{item.linkText}</CTAButton>
        )}

        {item.link && item.link !== "CUSTOM_PURCHASE_FLOW" && (
          authContext.loggedIn ? <FitplanLink link={item.branchLink ? item.branchLink : (item.link ? item.link : '#')} linkText= {item.linkText} /> : <FitplanLink link={item.link} linkText= {item.linkText} />
        )}
        {item.linkHelpText && (
          <span className="monthly-plan">{item.linkHelpText}</span>
        )}
      </div>
    </div>
  );

  const ImageBlock = ({ item, className = "" }) => {
    return (
      <div className={`col-6 image-section ${className}`}>
        <GatsbyImage image={getImage(item.desktopImage)} alt={item.heading} />
      </div>
    );
  };

  return (
    <>
      {items?.map((item) => (
        <section key={item.id} className="wecare-sec">
          <div className="container">
            {!flipBlocks && item ? (
              <>
                <TextBlock item={item} />
                <ImageBlock item={item} />
              </>
            ) : (
              <>
                <ImageBlock item={item} className="flip-image" />
                <TextBlock item={item} className="flip-text" />
              </>
            )}
          </div>
        </section>
      ))}
    </>
  );
};

export default TwoColumnContentRichImageBlock;

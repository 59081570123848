import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import "./scrollAnimationIcon.scss";

const ScrollAnimIcon = () => {
  const { t, language } = useTranslation();
  return (
    <div className="scroll-note">
      <span className="scroll-txt">{t("scroll")}</span>
      <div className="scroll-anim"></div>
    </div>
  );
};

export default ScrollAnimIcon;

import React, { useState, useEffect, useRef } from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import "./trainerListBlock.scss";

const TrainerListBlock = ({ data: { heading, subheading, items } }) => {
  const { t, language } = useTranslation();

  const [searchInput, setSearchInput] = useState("");
  const [openCombo, setOpenCombo] = useState(false);
  const [genderFilter, setGenderFilter] = useState("alltrainers");
  const [genderFilterVal, setGenderFilterVal] = useState("alltrainers");
  const [result, setResult] = useState(items);

  const genderDropDown = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const buttons = [
    { name: "alltrainers", value: "alltrainers" },
    { name: "female", value: false },
    { name: "male", value: true },
  ];

  function handleClickOutside(event) {
    if (
      genderDropDown.current &&
      !genderDropDown.current.contains(event.target)
    ) {
      setOpenCombo(false);
    }
  }
  function filterGender(value, name) {
    setGenderFilter(value);
    setGenderFilterVal(name);
    setOpenCombo(false);
  }

  function searchInputHandle(value) {
    setSearchInput(value);
  }

  let activeComboClass = "";
  if (openCombo) {
    activeComboClass = "active";
  }

  let filteredResult = result?.filter((filter) => {
    if (filter.trainerName) {
      return filter.trainerName
        .toLowerCase()
        .includes(searchInput.toLowerCase());
    }
  });
  if (items && items[0].trainerName) {
    return (
      <>
        <section className="fitplan-trainer-list">
          <div className="container">
            <div className="filter-trainer">
              <div className="filter search-trainer">
                <div className="search-wrap">
                  <input
                    type="text"
                    placeholder={t("search")}
                    id="search-trainer-input"
                    name="search-trainer-input"
                    className="search-trainer-input"
                    onChange={(event) => searchInputHandle(event.target.value)}
                  />
                </div>
              </div>

              <div className="filter filter-trainer-btns">
                <div
                  className={`combo-gender ${activeComboClass}`}
                  onClick={() => setOpenCombo(!openCombo)}
                  ref={genderDropDown}
                >
                  {t(genderFilterVal)}
                </div>
                <ul className={`filter ${activeComboClass}`}>
                  {buttons.map(({ name, value }) => {
                    let currentGender = "";
                    if (genderFilter == value) {
                      currentGender = "active";
                    }
                    return (
                      <li key={name}>
                        <button
                          value={value}
                          className={`btn-gender ${currentGender}`}
                          onClick={() => filterGender(value, name)}
                        >
                          {t(name)}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="trainer-list-wrap">
              {filteredResult.map((item, key) => {
                if (
                  item.metaTitle &&
                  (item.gender == genderFilter ||
                    genderFilter == "alltrainers")
                ) {
                  return (
                    <Link key={key} to={`/trainers/${item.slug}`}>
                      <div className="single-trainer-inner">
                        <div className="image">
                          <GatsbyImage
                            image={getImage(item.featureImage)}
                            alt={item.trainerName}
                            className="desktop"
                          />
                          <GatsbyImage
                            image={getImage(item.featureImageMobile)}
                            alt={item.trainerName}
                            className="mobile"
                          />
                        </div>
                        <div className="trainer-details">
                          <h3>{item.trainerName}</h3>
                          <div className="desc">
                            <p>{item.shortDescription}</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                }
              })}
            </div>
          </div>
        </section>
      </>
    );
  } else return null;
};

export default TrainerListBlock;

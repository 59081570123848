import React, { useState, useEffect, useRef } from "react";
import parse from "html-react-parser";

const TextOnlyBlock = ({
  data: {
    body: {
      childMarkdownRemark: { html },
    },
  },
}) => {
  return <>{html ? parse(html) : ""}</>;
};

export default TextOnlyBlock;

import React, { useState } from "react";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import VideoPlayer from "../videoPlayer/videoPlayer";
import "./aboutTrainer.scss";

const AboutTrainer = ({ data: { heading, subheading, items } }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClass(node)}>{children}</p>
      ),
    },
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  function paragraphClass(node) {
    const className = "odd";
    //alternate logic for 'odd' | 'even'
    return className;
  }

  const [showModal, setShowModal] = useState(false);

  function closeModalHandler() {
    setShowModal(false);
  }

  const { t, language } = useTranslation();
  const item = items[0];

  const [showMore, setshowMore] = useState(false);
  let activeClass = "";
  if (showMore) {
    activeClass = "active";
  }

  return (
    <>
      <section className="fitplan-about-section">
        <div className="image-aboutbg">
          <div className="imageInner">
            <GatsbyImage image={getImage(item.backgroundImage)} alt={heading} />
          </div>
        </div>
        <div className="container">
          <div className={`about-right ${activeClass}`}>
            <h2 className="fit-title">{heading}</h2>
            <div className="content fit-description ">
              {item.content && renderRichText(item.content, options)}
            </div>
            <a className="read-more" onClick={() => setshowMore(!showMore)}>
              {showMore ? (
                <span className="less">{t("readless")}</span>
              ) : (
                <span className="more">{t("readmore")}</span>
              )}
            </a>
            <hr className="read-hr" />

            {item.video && (
              <div className="round-btn-wrap popup-about">
                <a
                  className="round-btn-play"
                  onClick={() => setShowModal(!showModal)}
                >
                  <span className="play-round">
                    <svg
                      width="9"
                      height="8"
                      viewBox="0 0 7.99 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polygon points="7.99,4.66 0,9 0.19,0 " />
                    </svg>
                  </span>
                  {t("presentationVideo")}
                </a>
              </div>
            )}
          </div>
        </div>
      </section>

      {showModal && item.video ? (
        <VideoPopUp video={item.video} closeVideo={closeModalHandler} />
      ) : (
        ""
      )}
    </>
  );
};

const VideoPopUp = ({ video, closeVideo }) => {
  return (
    <>
      <div className="video-popup">
        <VideoPlayer
          videoId={`presentation-video`}
          videoItem={video}
          videoHeading={``}
        />
        {/* <div className="video-container">
          {!isValidHttpUrl(video) ? (<iframe
            src={`https://player.vimeo.com/video/${video}?title=0&byline=0&portrait=0"`}
            width="100%"
            height="360"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>) : (<div className="video-player-container">
            <video id={`presentation-video`} className="vidoe-player about-trainer-video-player">
              <source src={`${video}#t=0.2`} type="video/mp4" />
            </video>
          </div>)}
        </div> */}
      </div>
      <div className="overlay-popup" onClick={closeVideo}></div>
    </>
  );
};

export default AboutTrainer;

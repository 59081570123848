import React, { useState, useEffect, useRef } from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import "./planListBlock.scss";

const PlanListBlock = ({ data }) => {
  const { heading, subheading, items } = data;
  const { t, language } = useTranslation();

  const [searchInput, setSearchInput] = useState("");
  const [openCombo, setOpenCombo] = useState(false);
  const [openGoalCombo, setOpenGoalCombo] = useState(false);

  const [genderFilter, setGenderFilter] = useState("alltrainers");
  const [genderFilterVal, setGenderFilterVal] = useState("alltrainers");

  const [goalFilter, setGoalFilter] = useState("allGoals");
  const [goalFilterVal, setGoalFilterVal] = useState("allGoals");

  const [result, setResult] = useState(items);

  const genderDropDown = useRef(null);
  const goalDropDown = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const buttons = [
    { name: "alltrainers", value: "alltrainers" },
    { name: "female", value: false },
    { name: "male", value: true },
  ];

  const goals = [
    { name: "allGoals", value: "allGoals" },
    { name: "athleticPerformance", value: "Athletic Performance" },
    { name: "bootyGains", value: "Booty Gains" },
    { name: "buildMuscle", value: "Build Muscle" },
    { name: "toneAndTighten", value: "Tone & Tighten" },
    { name: "weightLoss", value: "Weight Loss" },
  ];

  function handleClickOutside(event) {
    if (
      genderDropDown.current &&
      !genderDropDown.current.contains(event.target)
    ) {
      setOpenCombo(false);
    }
    if (goalDropDown.current && !goalDropDown.current.contains(event.target)) {
      setOpenGoalCombo(false);
    }
  }

  function filterGender(value, name) {
    setGenderFilter(value);
    setGenderFilterVal(name);
    setOpenGoalCombo(false);
  }

  function filterGoal(name) {
    setGoalFilter(name);
    setGoalFilterVal(name);
    setOpenCombo(false);
  }

  function searchInputHandle(value) {
    setSearchInput(value);
  }

  let activeComboClass = "";
  if (openCombo) {
    activeComboClass = "active";
  }
  let activeComboClassGender = "";
  if (openGoalCombo) {
    activeComboClassGender = "active";
  }

  let filteredResult = result?.filter((filter) => {
    if (filter.planName) {
      return filter.planName.toLowerCase().includes(searchInput.toLowerCase());
    }
  });

  if (items) {
    return (
      <>
        <section className="fitplan-plan-list">
          <div className="container">
            <div className="filter-plan">
              <div className="filter filter-goal">
                <div className="goal-wrap combo-warp">
                  <div
                    className={`combo-goal dorp-down-value ${activeComboClassGender}`}
                    onClick={() => setOpenGoalCombo(!openGoalCombo)}
                    ref={goalDropDown}
                  >
                    {t(goalFilter)}
                  </div>
                  <ul
                    className={`filter-ul filter-drop ${activeComboClassGender}`}
                  >
                    {goals.map(({ name, value }) => {
                      let currentGender = "";
                      if (goalFilter == value) {
                        currentGender = "active";
                      }
                      return (
                        <li key={name}>
                          <button
                            value={value}
                            className={`btn-goal ${currentGender}`}
                            onClick={() => filterGoal(name)}
                          >
                            {t(name)}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="filter filter-plan-btns">
                <div
                  className={`combo-gender dorp-down-value ${activeComboClass}`}
                  onClick={() => setOpenCombo(!openCombo)}
                  ref={genderDropDown}
                >
                  {t(genderFilterVal)}
                </div>
                <ul className={`filter-ul filter-drop ${activeComboClass}`}>
                  {buttons.map(({ name, value }) => {
                    let currentGender = "";
                    if (genderFilter == value) {
                      currentGender = "active";
                    }
                    return (
                      <li key={name}>
                        <button
                          value={value}
                          className={`btn-gender ${currentGender}`}
                          onClick={() => filterGender(value, name)}
                        >
                          {t(name)}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="filter search-plan">
                <div className="search-wrap">
                  <input
                    type="text"
                    placeholder={t("search")}
                    id="search-plan-input"
                    name="search-plan-input"
                    className="search-plan-input"
                    onChange={(event) => searchInputHandle(event.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="plan-list-wrap">
              {filteredResult.map((item, key) => {
                if (
                  (item.metaTitle || item.planName) &&
                  (item.gender == genderFilter ||
                    genderFilter == "alltrainers")
                ) {
                  if ((item.planGoals && (item.planGoals.includes(goalFilter)) || goalFilter == "allGoals") && item.planName && item.featureImage.gatsbyImageData)
                  //if ((item.planGoal == goalFilter || goalFilter == "allGoals") && item.planName && item.featureImage.gatsbyImageData)
                    return (
                      <Link key={key} to={`/plans/${item.planSlug}`}>
                        <div className="single-plan-inner">
                          <div className="image">
                            <GatsbyImage
                              image={getImage(item.featureImage)}
                              alt={item.planName}
                              className="desktop"
                            />
                            <GatsbyImage
                              image={getImage(item.featureImageMobile)}
                              alt={item.planName}
                              className="mobile"
                            />
                          </div>
                          <div className="plan-details">
                            <h3>{item.planName}</h3>
                            <div className="desc">
                              <p>{item.shortDescription}</p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                }
              })}
            </div>
          </div>
        </section>
      </>
    );
  } else return null;
};

export default PlanListBlock;

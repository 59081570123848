import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import striptags from "striptags";
import "./staticHeroBlockWithBackgroundImage.scss";

const StaticHeroBlockWithBackgroundImage = ({ data }) => {
  const {
    backgroundImage,
    desktopImage: desktopImage1,
    mobileImage: desktopImage2,
    heading,
    subheading,
    id,
  } = data.items[0];
  return (
    <section className="landing-hero-background-image" id={id}>
      <GatsbyImage
        image={getImage(backgroundImage)}
        alt={striptags(heading)}
        placeholder="blurred"
        layout="fullwidth"
      />
      <div className="abs-all">
        <div className="container">
          <div className="image_wrapper">
            <GatsbyImage
              image={getImage(desktopImage1)}
              alt={striptags(heading)}
              placeholder="blurred"
              layout="constrained"
            />
          </div>
          <div className="text_wrapper">
            {heading && (
              <h1 className="landing-page-title">{parse(heading)}</h1>
            )}
            {subheading && (
              <h2 className="landing-page-qr-code">{subheading}</h2>
            )}
            <div className="qr-code-view">
              <GatsbyImage
                image={getImage(desktopImage2)}
                alt={striptags(heading)}
                placeholder="blurred"
                layout="constrained"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StaticHeroBlockWithBackgroundImage;
